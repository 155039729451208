import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import ShopLayout from "../Layout";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  AddBtn,
  Loader,
  LoaderFull,
  PaginationThree,
  SubmitResetBtn,
} from "../../common";
import moment from "moment";
import axios from "axios";
import styles from "../../../styles/tailwind/List.module.css";
import { getPage, getParamValue } from "../../../utils";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Empty,
  Form,
  Input,
  Pagination,
  Radio,
  Select,
  Switch,
  Tabs,
  Tag,
} from "antd";
import classNames from "classnames";
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  PlusOutlined,
  SearchOutlined,
  SwapOutlined,
  ToTopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { error } from "console";
import { responseNotification } from "../../../utils/notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const ShopPackagesAdd = (): ReactElement => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [showSearch, setShowSearch] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [size, setSize] = useState<SizeType>("large");
  const [loading, setLoading] = useState(false);
  const [allChecked, setAllChecked] = useState<any>(false);
  const [isChecked, setIsChecked] = useState<any>([]);
  const [domesticZoneType, setDomesticZoneType] = useState<any>("DOMESTIC");
  const [internationalZoneType, setInternationalZoneType] =
    useState<any>("INTERNATIONAL");
  const [zoneData, setZoneData] = useState<any>({
    loading: false,
    data: null,
  });
  const [internationalZoneData, setInternationalZoneData] = useState<any>({
    loading: false,
    data: null,
  });
  const [shippingDeliveryType, setShippingDeliveryType] = useState<any>();
  const [packingUnitType, setPackingUnitType] = useState<any>();
  const [whoPrepsUnit, setWhoPrepsUnit] = useState<any>(undefined);
  const [whoLabelUnits, setWhoLabelUnits] = useState<any>(undefined);
  const [defaultSellerPackage, setDefaultSellerPackage] = useState(false);
  const [packingType, setPackingType] = useState<any>();
  const route = useParams();
  const loc = useLocation();
  const shopId = (route as any)?.shopId;
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [shopData, setShopData] = useState<any>({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      name: data.name,
      packageUnitType: {
        caseUnits: packingUnitType === "caseUnits" ? true : false,
        groupUnits: packingUnitType === "groupUnits" ? true : false,
        individualUnits: packingUnitType === "individualUnits" ? true : false,
      },
      packageType: {
        box: packingType === "box" ? true : false,
        envelope: packingType === "envelope" ? true : false,
        softPackageOrSatchel:
          packingType === "softPackageOrSatchel" ? true : false,
      },
      isCustomPackage: true,
      shopId: (route as any)?.shopId,
      whoLabelsUnits: whoLabelUnits,
      whoPrepsUnits: whoPrepsUnit,
      packageSizeAndWeight: {
        height: data.height,
        length: data.length,
        totalBoxWeight: data.weight,
        unitPerBox: data.unitPerBox,
        unitType: data.unit,
        width: data.width,
      },
      isMakeThisTheDefaultSellerPackage: defaultSellerPackage,
    };
    await fetch(`${process.env.REACT_APP_ORDER_API}/package/add-package`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Package Create Successfully", "success");
          form.resetFields();
          navigate(-1);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };
  const handleAllCheck = (e) => {
    if (allChecked) {
      setAllChecked(false);
      return setIsChecked([]);
    }
    setAllChecked(true);
    //return setIsChecked(zoneData.map(data => data.name));
    return setIsChecked(zoneData?.data?.deliveryConfigurations?.map(zone => zone?.ownShippingRates?.map(shippingRate => shippingRate.rateId)));
  };

  const handleSingleCheck = (e) => {
    const { name } = e.target;
    if (isChecked.includes(name)) {
      setIsChecked(isChecked.filter((checked_name) => checked_name !== name));
      return setAllChecked(false);
    }
    isChecked.push(name);
    setIsChecked([...isChecked]);
    setAllChecked(isChecked.length === zoneData.length)
  };

  const getDomesticZones = useCallback(async () => {
    setZoneData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/delivery-configuration/all?shopId=${shopId}&zoneType=${domesticZoneType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setZoneData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setZoneData({ loading: false, data: [] });
        console.error("Zones: Error", err);
      });
  }, [shopId]);
  const getInternationalZones = useCallback(async () => {
    setInternationalZoneData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/delivery-configuration/all?shopId=${shopId}&zoneType=${internationalZoneType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setInternationalZoneData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setInternationalZoneData({ loading: false, data: [] });
        console.error("Zones: Error", err);
      });
  }, [shopId]);
  useEffect(() => {
    getDomesticZones();
    getInternationalZones();
  }, [getDomesticZones, getInternationalZones]);

  return (
    <React.Fragment>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Brand List"
        subTitle="BrandList"
        extra={<></>}
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <div className={styles.contentWrapper}>
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div
                    className={
                      showSearch ? `content-body-withSearch` : `content-body`
                    }
                  >
                    <div className="content-body">
                      <>
                        <Form
                          name="control-hooks"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          className={styles.formStyles}
                          form={form} // like ref
                          onFinish={onSubmit}
                          layout="horizontal"
                        >
                          <Card className="mx-8 my-4 rounded-xl m">
                            <div className="mt-4">
                              <Form.Item
                                hasFeedback
                                label="Name"
                                rules={[
                                  {
                                    required: false,
                                    message: "Name is Required!",
                                  },
                                ]}
                                name="name"
                              >
                                <Input
                                  id="name"
                                  type="text"
                                  placeholder="Type your Shipping profile name"
                                />
                              </Form.Item>
                              <p>Customers won’t see this</p>
                            </div>
                          </Card>
                          <Card className="mx-8 my-4 rounded-xl">
                            <div className="float-left w-full">
                              <p className="float-left w-4/5 text-left">
                                Product
                              </p>
                              <p className="float-left w-1/5 text-right">
                                Add Product
                              </p>
                            </div>
                            <div className="float-left w-full h-16 bg-zinc-700 rounded-md text-center">
                              No Data
                            </div>
                          </Card>
                          <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                            Shipping Origins
                          </h3>
                          <Card className="mx-8 my-4 rounded-xl">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                <div
                                  className={
                                    showSearch
                                      ? `content-body-withSearch`
                                      : `content-body`
                                  }
                                >
                                  {zoneData?.loading ? (
                                    <Loader />
                                  ) : (
                                    <>
                                      {zoneData?.data?.deliveryConfigurations
                                        ?.length ? (
                                        zoneData?.data?.deliveryConfigurations?.map(
                                          (zone: any, index: any) => (
                                            
                                            <>
                                              <h1 className="p-0 text-justify text-lg">
                                                {zone?.deliveryZoneName}
                                              </h1>
                                              <AddBtn
                                                onClick={() => {
                                                  navigate(
                                                    `/shops/${
                                                      (route as any)?.shopId
                                                    }/shop-add-rate/${zone?.id}`
                                                  );
                                                }}
                                              />
                                              <Tabs defaultActiveKey="1">
                                                <Tabs.TabPane
                                                  tab="Your Shipping Rates"
                                                  key="1"
                                                >
                                                  <table
                                                    className={styles.mainTable}
                                                  >
                                                    <thead className="bg-white border-b">
                                                      <tr>
                                                        <th scope="col">
                                                          <Checkbox
                                                            name="checkall"
                                                            className="mr-3"
                                                            checked={allChecked}
                                                            onChange={
                                                              handleAllCheck
                                                            }
                                                          >
                                                            All
                                                          </Checkbox>
                                                        </th>
                                                        <th scope="col">
                                                          Shipping Rate name
                                                        </th>
                                                        <th scope="col">
                                                          Condition
                                                        </th>
                                                        <th scope="col">
                                                          Transit time
                                                        </th>
                                                        <th scope="col">
                                                          Shipping Price
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {zone?.ownShippingRates?.map(
                                                        (
                                                          shippingRate: any,
                                                          shippingRateIndex: any
                                                        ) => (
                                                            
                                                          <>
                                                            <tr>
                                                              <td>
                                                                <Checkbox
                                                                  name={
                                                                    shippingRate.rateId
                                                                  }
                                                                  className="mr-3"
                                                                  checked={isChecked.includes(
                                                                    shippingRate.rateId
                                                                  )}
                                                                  onChange={
                                                                    handleSingleCheck
                                                                  }
                                                                />
                                                              </td>
                                                              <td>
                                                                {shippingRate?.shippingTransitType.replace(
                                                                  "_",
                                                                  " "
                                                                )}
                                                              </td>
                                                              <td>
                                                                {
                                                                  shippingRate?.isConditions
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  shippingRate?.rateId
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  shippingRate?.shippingPrice
                                                                }
                                                              </td>
                                                            </tr>
                                                          </>
                                                        )
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </Tabs.TabPane>

                                                <Tabs.TabPane
                                                  tab="Carrier and app Shipping rates"
                                                  key="2"
                                                >
                                                  <table
                                                    className={styles.mainTable}
                                                  >
                                                    <thead className="bg-white border-b">
                                                      <tr>
                                                        <th scope="col">
                                                          Shipping Rate name
                                                        </th>
                                                        <th scope="col">
                                                          Condition
                                                        </th>
                                                        <th scope="col">
                                                          Transit time
                                                        </th>
                                                        <th scope="col">
                                                          Shipping Price
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {zone?.carrierShippingRates?.map(
                                                        (
                                                          currentShippingRate: any,
                                                          currentShippingRateIndex: any
                                                        ) => (
                                                          <>
                                                            <tr>
                                                              <td>
                                                                {currentShippingRate?.shippingTransitType.replace(
                                                                  "_",
                                                                  " "
                                                                )}
                                                              </td>
                                                              <td>
                                                                {
                                                                  currentShippingRate?.isConditions
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  currentShippingRate?.rateId
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  currentShippingRate?.shippingPrice
                                                                }
                                                              </td>
                                                            </tr>
                                                          </>
                                                        )
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </Tabs.TabPane>
                                              </Tabs>
                                            </>
                                          )
                                        )
                                      ) : (
                                        <Empty />
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>

                            <Pagination
                              {...zoneData?.data}
                              limit={limit}
                              page={getPage(loc.search)}
                            />
                          </Card>
                          <h3 className="mb-2 mt-0 text-3xl font-medium leading-tight text-primary px-8">
                            Shipping Zones
                          </h3>
                          <Card className="mx-8 my-4 rounded-xl">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                <div
                                  className={
                                    showSearch
                                      ? `content-body-withSearch`
                                      : `content-body`
                                  }
                                >
                                  {internationalZoneData?.loading ? (
                                    <Loader />
                                  ) : (
                                    <>
                                      {internationalZoneData?.data
                                        ?.deliveryConfigurations?.length ? (
                                        internationalZoneData?.data?.deliveryConfigurations?.map(
                                          (zone: any, index: any) => (
                                            <>
                                              <h1 className="p-0 text-justify text-lg">
                                                {zone?.deliveryZoneName}
                                              </h1>
                                              <AddBtn
                                                onClick={() => {
                                                  navigate(
                                                    `/shops/${
                                                      (route as any)?.shopId
                                                    }/shop-add-rate/${zone?.id}`
                                                  );
                                                }}
                                              />
                                              <Tabs defaultActiveKey="1">
                                                <Tabs.TabPane
                                                  tab="Your Shipping Rates"
                                                  key="1"
                                                >
                                                  <table
                                                    className={styles.mainTable}
                                                  >
                                                    <thead className="bg-white border-b">
                                                      <tr>
                                                        <th scope="col">
                                                          Shipping Rate name
                                                        </th>
                                                        <th scope="col">
                                                          Condition
                                                        </th>
                                                        <th scope="col">
                                                          Transit time
                                                        </th>
                                                        <th scope="col">
                                                          Shipping Price
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {zone?.ownShippingRates?.map(
                                                        (
                                                          shippingRate: any,
                                                          shippingRateIndex: any
                                                        ) => (
                                                          <>
                                                            <tr>
                                                              <td>
                                                                {shippingRate?.shippingTransitType.replace(
                                                                  "_",
                                                                  " "
                                                                )}
                                                              </td>
                                                              <td>
                                                                {
                                                                  shippingRate?.isConditions
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  shippingRate?.rateId
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  shippingRate?.shippingPrice
                                                                }
                                                              </td>
                                                            </tr>
                                                          </>
                                                        )
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </Tabs.TabPane>

                                                <Tabs.TabPane
                                                  tab="Carrier and app Shipping rates"
                                                  key="2"
                                                >
                                                  <table
                                                    className={styles.mainTable}
                                                  >
                                                    <thead className="bg-white border-b">
                                                      <tr>
                                                        <th scope="col">
                                                          Shipping Rate name
                                                        </th>
                                                        <th scope="col">
                                                          Condition
                                                        </th>
                                                        <th scope="col">
                                                          Transit time
                                                        </th>
                                                        <th scope="col">
                                                          Shipping Price
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {zone?.carrierShippingRates?.map(
                                                        (
                                                          currentShippingRate: any,
                                                          currentShippingRateIndex: any
                                                        ) => (
                                                          <>
                                                            <tr>
                                                              <td>
                                                                {currentShippingRate?.shippingTransitType.replace(
                                                                  "_",
                                                                  " "
                                                                )}
                                                              </td>
                                                              <td>
                                                                {
                                                                  currentShippingRate?.isConditions
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  currentShippingRate?.rateId
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  currentShippingRate?.shippingPrice
                                                                }
                                                              </td>
                                                            </tr>
                                                          </>
                                                        )
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </Tabs.TabPane>
                                              </Tabs>
                                            </>
                                          )
                                        )
                                      ) : (
                                        <Empty />
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>

                            <Pagination
                              {...internationalZoneData?.data}
                              limit={limit}
                              page={getPage(loc.search)}
                            />
                          </Card>

                          <Card className="mx-8 my-4 rounded-xl">
                            <div className="buttons-container">
                              <Button
                                disabled={loading}
                                loading={
                                  (loading ? "loading" : undefined) as any
                                }
                                type="primary"
                                htmlType="submit"
                                className="add-submit-btn text-center mr-2"
                              >
                                Submit
                              </Button>

                              <Button
                                htmlType="button"
                                onClick={() => {
                                  form?.resetFields();
                                }}
                                className="reset-submit-btn text-center mr-2"
                              >
                                Reset
                              </Button>
                            </div>
                          </Card>
                        </Form>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ShopLayout>
    </React.Fragment>
  );
};

ShopPackagesAdd.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopPackagesAdd;
