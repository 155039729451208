import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
  } from "@ant-design/icons";
  import axios from "axios";
  import { ReactElement, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
  import { AutoComplete, Avatar, Button, Drawer, Empty, Form, Input, Popconfirm, Select, Spin, Switch } from "antd";
  import BreadCrumb from "../Layouts/Breadcrumb";
  import { responseNotification } from "../../utils/notify";
  import { getPage, getParamValue } from "../../utils";
  import { useLocation } from "react-router";
  import styles from "../../styles/tailwind/List.module.css";
  import { useSelector } from "react-redux";
  import SingleCity from "./Details";
  import AddCity from "./AddCity";
  import moment from "moment";
  import React from "react";
  import { AddBtn, Loader, Pagination } from "../common";
import { debounce } from "lodash";
  
  const CityList = (): ReactElement => {
    const { type, token } = useSelector((state) => (state as any)?.authReducer);
    const loc = useLocation();
    const page = getParamValue(loc.search, "page");
    const [limit, setLimit] = useState(16);
    const [status, setStatus] = useState("");
    const fetchRef = useRef(0);
    const [form] = Form.useForm();
    const [key, setKey] = useState("");
    const [stateId, setStateId] = useState<any>("65f14497186b322ebb194bab");
    const [countryId, setCountryId] = useState<any>();
    const [showSearch, setShowSearch] = useState(true);
    const [isForLanding, setIsForLanding] = useState<boolean>(false);
    const [visible, setVisible] = useState<any>(undefined);
    const [selectedCity, setSelectedCity] = useState(undefined);
    const [selectedCityForEdit, setSelectedCityForEdit] = useState(undefined);
    const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = (id: any) => {
      setVisiblePopconfirm(id);
    };
    const [cityData, setCityData] = useState<any>({
      loading: false,
      data: null,
    });
    const [countryOptions, setCountryOptions] = useState({
        loading: false,
        list: [],
      });
      const [stateOptions, setStateOptions] = useState({
        loading: false,
        list: [],
      });
      const [cityOptions, setCityOptions] = useState({
        loading: false,
        list: [],
      });
    const getCitys = useCallback(async () => {
      setCityData({ loading: true, data: null });
  
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      axios
        .get(
          `${encodedUri}/admin/country-state/cities?limit=${limit}`+
          (stateId ? `&stateId=${stateId}` : ``)+
          (key ? `&key=${key}` : ``)+
          (page
            ? `&page=${
                page == cityData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) ,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setCityData({ loading: false, data: res.data });
        })
        .catch((err) => {
          setCityData({ loading: false, data: [] });
          console.error("Citys: Error", err);
        });
    }, [limit,stateId,key,page]);
  
    const getCityOptions = useCallback(
      async (key: any) => {
        setCityOptions({ loading: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
        let url =
          `?page=0&limit=20` +
          (stateId ? `&stateId=${stateId}` : ``)+
          (key ? `&key=${key}` : ``);
        url = encodeURI(url);
        return axios
          .get(`${encodedUri}/admin/country-state/cities?${url}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setCityOptions({
              loading: false,
              list: res.data?.cities?.map((citie: { name: any }) => ({
                label: citie.name,
                value: citie.name,
              })),
            });
          })
          .catch((err) => {
            setCityOptions({ loading: false, list: [] });
            console.error("Products: Error", err);
          });
      },
      [stateId,key,page, limit]
    );
    const getCountryOptions = useCallback(
        async (key: any) => {
            setCountryOptions({ loading: true, list: [] });
          const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
          let url =
            `?page=0&limit=20` +
            (key ? `&key=${key}` : ``);
          url = encodeURI(url);
    
          return axios
            .get(`${encodedUri}/admin/country-state/countries${url}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
                setCountryOptions({
                loading: false,
                list: res.data?.countries?.map((country:any) => ({
                  label: country.name,
                  value: country.id,
                })),
              });
            })
            .catch((err) => {
                setCountryOptions({ loading: false, list: [] });
              console.error("Products: Error", err);
            });
        },
        [key]
      );
      const getStateOptions = useCallback(
        async (key: any) => {
          setStateOptions({ loading: true, list: [] });
          const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
          let url =
            `?page=0&limit=20` +
            (countryId ? `&countryId=${countryId}` : ``)+
            (key ? `&key=${key}` : ``);
          url = encodeURI(url);
    
          return axios
            .get(`${encodedUri}/admin/country-state/states${url}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              setStateOptions({
                loading: false,
                list: res.data?.states?.map((state:any) => ({
                  label: state.name,
                  value: state.id,
                })),
              });
            })
            .catch((err) => {
              setStateOptions({ loading: false, list: [] });
              console.error("city: Error", err);
            });
        },
        [countryId,key]
      );

      const onCityRemove = async (id: any) => {
        if (id) {
          await fetch(`${process.env.REACT_APP_RIDER_API}/admin/country-state/update-city`, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: id,
              deleted: true,
            }),
          })
            .then((res) => res.json())
            .then((res) => {
              if (res.statusCode === 200) {
                responseNotification("City Remove Successfully", "success");
                getCitys();
              } else if (res.status === 500) {
                responseNotification("Internal server error", "error");
              } else {
                responseNotification(res.message || "something wrong", "warning");
              }
            })
            .catch((err) => {
              responseNotification(`${"Internal server error"} ${err}`, "error");
              console.error("err", err);
            });
        }
      };

      const onStatusChange = async (id: any, val: any) => {
        setConfirmLoading(true);
        if (id) {
          await fetch(`${process.env.REACT_APP_RIDER_API}/admin/country-state/update-city`, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: id,
              isActive: val,
            }),
          })
            .then((res) => res.json())
            .then((res) => {
              if (res.statusCode === 200) {
                responseNotification("Status Changed Successfully", "success");
                getCitys();
                setVisible(false);
              } else if (res.status === 500) {
                responseNotification("Internal server error", "error");
              } else {
                responseNotification(res.message || "something wrong", "warning");
              }
            })
            .catch((err) => {
              responseNotification(`${"Internal server error"} ${err}`, "error");
              console.error("err", err);
            });
        }
      };

      useEffect(() => {
        getCountryOptions("");
        getStateOptions("");
        getCityOptions("");
      
    }, [getCountryOptions,getStateOptions,getCityOptions]);

    useEffect(() => {
      getCitys();
    }, [getCitys]);
  
    const onClose = () => {
      getCitys();
      setVisible(undefined);
      setSelectedCity(undefined);
      setSelectedCityForEdit(undefined);
    };
    const handleCancel = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setVisiblePopconfirm(undefined);
    };
    const handleSearch = React.useMemo(() => {
        const loadOptions = (value: string, field: string) => {
          fetchRef.current += 1;
          const fetchId = fetchRef.current;
    
          if (fetchId !== fetchRef.current) {
            return;
          }
          if (value) {
            if (field === "country") getCountryOptions(value);
            if (field === "state") getStateOptions(value);
            if (field === "city") getCityOptions(value);
          }
        };
    
        return debounce(loadOptions, 800);
      }, [getCountryOptions,getStateOptions,getCityOptions]);

    const reseAllFieldData = () => {
        setCountryId("");
        setStateId("");
        setKey("");
        form.resetFields();
      };
    return (
      <>
        <BreadCrumb
          title="City List"
          subTitle={`${
            cityData?.data?.totalElements ||
            cityData?.data?.cities?.length ||
            0
          } City(s)`}
          extra={[
            <AddBtn onClick={() => setVisible(true)} key={2} />,
          ]}
        />
        {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
                
            <Form.Item name="country_search" className="pb-4">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Country"
                optionFilterProp="children"
                onChange={(val) => setCountryId(val)}
                onSearch={(e) => handleSearch(e, "country")}
                filterOption={() => {
                  return true;
                }}
                options={countryOptions?.list}
              ></Select>
            </Form.Item>
        

          <Form.Item name="state_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by State"
                optionFilterProp="children"
                onChange={(val) => setStateId(val)}
                onSearch={(e) => handleSearch(e, "brand")}
                filterOption={() => {
                  return true;
                }}
                options={stateOptions?.list}
              ></Select>
            </Form.Item>
            <Form.Item name="search city name" className="pb-4">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "city")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val?.toString())
                }
                options={cityOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  cityOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={cityOptions.loading}
                />
              </AutoComplete>
            </Form.Item>
            
            </Form>
          
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
        <div className={styles.contentWrapper}>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div
                className={
                  showSearch ? `content-body-withSearch` : `content-body`
                }
              >
                {cityData?.loading ? (
                  <Loader />
                ) : (
                  <table className={styles.mainTable}>
                    <thead className="bg-white border-b">
                      <tr>
                        <th scope="col">City</th>
                        <th scope="col">Latitude</th>
                        <th scope="col">Longitude</th>
                        <th scope="col">Updated At</th>
                        <th scope="col">Updated By</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
  
                    <tbody>
                      {cityData?.data?.cities?.length ? (
                        cityData?.data?.cities?.map(
                          (city: any, index: any) => (
                            <tr
                              className="border-t hover:bg-gray-100"
                              key={index}
                              onClick={() => setSelectedCity(city)}
                            > 
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {city?.name}
                                </span>
                              </td>
                              <td>
                              <span className="font-medium text-gray-500 ml-2">
                                  {city?.latitude}
                                </span>
                              </td>
                              <td>
                              <span className="font-medium text-gray-500 ml-2">
                                  {city?.longitude}
                                </span>
                              </td>
                              <td>
                                <small
                                  className={`font-capitalize ${
                                    moment().diff(city?.updatedAt, "minutes") >=
                                    60
                                      ? "text-red-600"
                                      : "text-gray-800"
                                  }`}
                                >
                                  {moment(city?.updatedAt).fromNow()}
                                </small>
                                <br />
                                <span className="name">
                                  {moment(city?.updatedAt).format("lll")}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {city?.updatedBy?.name ||
                                    city?.updatedBy?.mobileNumber}
                                </span>
                              </td>
                              <td>
                                <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                  defaultChecked={city?.isActive}
                                  onChange={(val, e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onStatusChange(city?.id, val);
                                  }}
                                  loading={
                                    confirmLoading &&
                                    city?.id === confirmLoading
                                  }
                                />
                              </td>
                              <td>
                                <div className="flex gap-2">
                                  <Button
                                    shape="round"
                                    type="dashed"
                                    className="bg-white"
                                    onClick={() =>
                                      setSelectedCityForEdit(city)
                                    }
                                  >
                                    <EditOutlined />
                                  </Button>
                                  <Popconfirm
                                  placement="left"
                                  title="Are you sure to remove?"
                                  visible={city?.id === visiblePopconfirm}
                                  onConfirm={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onCityRemove(city?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(city?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td>
                            <Empty />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
  
          <Pagination
            {...cityData?.data}
            limit={limit}
            page={getPage(loc.search)}
          />
        </div>
  
        <Drawer
          title={
            visible
              ? "Add City"
              : selectedCityForEdit
              ? "Edit City"
              : "City Details"
          }
          placement="right"
          onClose={onClose}
          open={visible || selectedCity || selectedCityForEdit}
          width={450}
        >
          {visible || selectedCityForEdit ? (
            <AddCity
              onCloseMethod={onClose}
              visibleData={selectedCityForEdit}
            />
          ) : (
            <SingleCity CityDetails={selectedCity} />
          )}
        </Drawer>
      </>
    );
  };
  
  export default CityList;
  